export default {
  title: "Hello Infinite Italian",
  profile: "Profile Italian",
  hello: "Hola",
  changeLang: "Change Language",
  changeTheme: "Change Theme",
  equipment: "Attrezzatura",
  aiCameras: "Telecamere AI",
  envrSensor: "Env. Sensore",
  floodSensor: "Pavimento Sensore",
  search: "Ricerca",
  noResult: "Nessun Risultato Frovato",
  grokEye: "Grok Occhio",
  raiseAlert: "Alza l'allerta",
  call: "Chiamata",
  connectivity: "Connettività o Cellulare",
  incidents: "Incidenti",
  operationAlert: "Avviso Operativo",
  motion: "Movimento",
  logout: "Disconnettersi",
  dashboard: "Pannello Di Controllo",
  alerts: "Avvisi",
  poweredBy: "Offerto da",
  copyRight: "© 2023. Tutti i diritti riservati",
  events: "Eventi",
  operations: "Operazioni",


  temperature: "Temperatura",
  humidity: "Umidità",  
  carbonMonoxideWithoutUnit: "Monossido Di Carbonio",
  vocs: "VOC's",
  particulate: "Particolato",
  noise: "Rumore",
  pressure: "Pressione",
  light: "Leggero",
  waterLevel: "Livello Dell'acqua",
  rainfall: "Pioggia",
  battery: "batteria",


  carbonMonoxide: "Monossido Di Carbonio (Kg)",
  voc: "VOC",  
  call911: "Chiamata 911",
  viewDetails: "Visualizza Dettagli",
  aiCamera: "Telecamere AI",
  zone: "Zona",

  video: "video",
  image: "Immagine",
};
