const alerts = {
  events: [
    {
      id: "",
      category: "events",
      subCategory: "aiCameras",
      observation: "Maintenance Inprogress",
      name: "AI Camera #2",
      zone: "Zone 2",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95491207509093,
        lng: -75.19192976245738,
      },
    },
    {
      id: "",
      category: "events",
      subCategory: "envSensors",
      observation: "Maintenance Completed",
      name: "ENV Sensor #10",
      zone: "Zone 5",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95437555110006,
        lng: -75.1906751931307,
      },
    },
    {
      id: "",
      category: "events",
      subCategory: "floodSensors",
      observation: "Maintenance Inprogress",
      name: "Flood Sensor #2",
      zone: "Zone 2",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.9546330296282,
        lng: -75.19113445372379,
      },
    },
    {
      id: "",
      category: "events",
      subCategory: "floodSensors",
      observation: "Maintenance Completed",
      name: "Flood Sensor #9",
      zone: "Zone 2",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.954591817707524,
        lng: -75.19019137355684,
      },
    },
    {
      id: "",
      category: "events",
      subCategory: "aiCameras",
      observation: "Maintenance Completed",
      name: "AI Camera #1",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95488743558454,
        lng: -75.19127139530826,
      },
    },
  ],
  alerts: [
    {
      id: "",
      category: "alerts",
      subCategory: "aiCameras",
      observation: "Loitering Detected",
      name: "AI Camera #3",
      zone: "Zone 3",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.9543553048263,
        lng: -75.19161281863234,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "aiCameras",
      observation: "Intrusion Detected",
      name: "AI Camera #4",
      zone: "Zone 4",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95428996454814,
        lng: -75.19129107262624,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "aiCameras",
      observation: "Camera Not Operational",
      name: "AI Camera #5",
      zone: "Zone 5",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95428076168706,
        lng: -75.19098373315772,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "envSensors",
      observation: "High Temperature",
      name: "Env Sensor #3",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.954639672355675,
        lng: -75.19164643387612,
      },
    },
    // {
    //   id: "",
    //   category: "alerts",
    //   subCategory: "envSensors",
    //   observation: "Landslide Detected",
    //   name: "Env Sensor #5",
    //   zone: "Zone 4",
    //   area: "Construction Area",
    //   img: "",
    //   video: "",
    //   timeStamp: "12-15-2022 | 09:00 AM",
    //   location: {
    //     lat: 39.95436082653723,
    //     lng: -75.19134869876872,
    //   },
    // },
    {
      id: "",
      category: "alerts",
      subCategory: "envSensors",
      observation: "High Pressure",
      name: "Env Sensor #8",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.9546330296282,
        lng: -75.19113445372379,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "envSensors",
      observation: "High Particulate Matter",
      name: "Env Sensor #5",
      zone: "Zone 4",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95436082653723,
        lng: -75.19134869876872,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "envSensors",
      observation: "High Noise Level",
      name: "Env Sensor #7",
      zone: "Zone 4",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.954474941793684,
        lng: -75.19110018599535,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "floodSensors",
      observation: "High Temperature",
      name: "Flood Sensor #2",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95466636050886,
        lng: -75.19159000826745,
      },
    },
    {
      id: "",
      category: "alerts",
      subCategory: "floodSensors",
      observation: "High Water Level",
      name: "Flood Sensor #8",
      zone: "Zone 4",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95441420305054,
        lng: -75.19125625681502,
      },
    },
  ],
  operations: [
    {
      id: "",
      category: "operations",
      subCategory: "aiCameras",
      observation: "Maintenance Due",
      name: "AI Camera #6",
      zone: "Zone 6",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95461942616041,
        lng: -75.19013134630154,
      },
    },
    {
      id: "",
      category: "operations",
      subCategory: "envSensors",
      observation: "Intrution Detected",
      name: "AI Camera #2",
      zone: "Zone 2",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.9543583199674,
        lng: -75.19043868202611,
      },
    },
    {
      id: "",
      category: "operations",
      subCategory: "floodSensors",
      observation: "Maintenance Due",
      name: "Flood Sensor #1",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.95461206390933,
        lng: -75.19155039028908,
      },
    },
    {
      id: "",
      category: "operations",
      subCategory: "floodSensors",
      observation: "High Maintenance Hours",
      name: "Flood Sensor #15",
      zone: "Zone 1",
      area: "Construction Area",
      img: "",
      video: "",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        lat: 39.954709613700246,
        lng: -75.19078684377709,
      },
    },
  ],
};

export default alerts;
