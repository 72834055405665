const dashboardInfoWindow = {
  aiCamerasInfoData: [
    {
      id: "aiCamera1",
      category: "aiCamera",
      title: "AI Camera #1",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "79%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
    {
      id: "aiCamera2",
      category: "aiCamera",
      title: "AI Camera #2",
      area: "Construction Area, Zone 2",
      connectivityPercentage: "69%",
      incidentsObservation: "12 min ago",
      operationAlertObservation: "1 days ago",
      motionObservation: "25 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
    {
      id: "aiCamera3",
      category: "aiCamera",
      title: "AI Camera #3",
      area: "Construction Area, Zone 3",
      connectivityPercentage: "89%",
      incidentsObservation: "42 min ago",
      operationAlertObservation: "2 days ago",
      motionObservation: "35 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
    {
      id: "aiCamera4",
      category: "aiCamera",
      title: "AI Camera #4",
      area: "Construction Area, Zone 4",
      connectivityPercentage: "59%",
      incidentsObservation: "12 min ago",
      operationAlertObservation: "1 days ago",
      motionObservation: "35 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
    {
      id: "aiCamera5",
      category: "aiCamera",
      title: "AI Camera #5",
      area: "Construction Area, Zone 5",
      connectivityPercentage: "89%",
      incidentsObservation: "42 min ago",
      operationAlertObservation: "2 days ago",
      motionObservation: "35 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
    {
      id: "aiCamera6",
      category: "aiCamera",
      title: "AI Camera #6",
      area: "Construction Area, Zone 6",
      connectivityPercentage: "79%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "1 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      video: "",
      img: "",
    },
  ],
  envrSensorInfoData: [
    {
      id: "envrSensor1",
      category: "envrSensors",
      title: "Environment Sensor#1",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor2",
      category: "envrSensors",
      title: "Environment Sensor#2",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor3",
      category: "envrSensors",
      title: "Environment Sensor#3",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    // {
    //   id: "envrSensor3",
    //   category: "envrSensors",
    //   title: "Environment Sensor#3",
    //   area: "Construction Area, Zone 1",
    //   connectivityPercentage: "59%",
    //   incidentsObservation: "32 min ago",
    //   operationAlertObservation: "3 days ago",
    //   motionObservation: "5 sec ago",
    //   timeStamp: "12-15-2022 | 09:00 AM",

    //   infoIconList: [
    //     {
    //       title: "Temperature",
    //       value: "25°C",
    //     },
    //     {
    //       title: "Humidity",
    //       value: "40%",
    //     },
    //     {
    //       title: "Carbon Monoxide",
    //       value: "25 Kg",
    //     },
    //     {
    //       title: "VOC's",
    //       value: "551",
    //     },
    //     {
    //       title: "Particulate",
    //       value: "136µg/m³",
    //     },
    //     {
    //       title: "Noise",
    //       value: "70dBA",
    //     },
    //     {
    //       title: "Pressure",
    //       value: "10hPa",
    //     },
    //     {
    //       title: "Light",
    //       value: "60%",
    //     },
    //   ],
    //   analytics: [
    //     {
    //       type: "temperature",
    //       data: {
    //         day: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 44.0,
    //             },
    //           ],
    //         },
    //         weekly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //           ],
    //         },
    //         monthly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 74.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 73.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 58.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 87.0,
    //             },
    //           ],
    //         },
    //         yearly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 29.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 97.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 87.0,
    //             },
    //           ],
    //         },
    //       },
    //     },
    //     {
    //       type: "humidity",
    //       data: {
    //         day: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 67.0,
    //             },
    //           ],
    //         },
    //         weekly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //           ],
    //         },
    //         monthly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 52.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 87.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //           ],
    //         },
    //         yearly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //           ],
    //         },
    //       },
    //     },
    //     {
    //       type: "carbonMonoxide",
    //       data: {
    //         day: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 70.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 50.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 78.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 14.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 50.0,
    //             },
    //           ],
    //         },
    //         weekly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //           ],
    //         },
    //         monthly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 11.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 87.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 22.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 40.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 18.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 74.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 70.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 29.0,
    //             },
    //           ],
    //         },
    //         yearly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 74.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 50.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 71.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 44.0,
    //             },
    //           ],
    //         },
    //       },
    //     },
    //     {
    //       type: "voc",
    //       data: {
    //         day: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 71.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 50.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 81.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 67.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 30.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 58.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 37.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 11.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 52.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //           ],
    //         },
    //         weekly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 71.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 31.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 87.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 28.0,
    //             },
    //           ],
    //         },
    //         monthly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 77.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 51.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 57.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 87.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 74.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 79.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 60.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 11.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 78.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 20.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 41.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 34.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 17.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 69.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 87.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 16.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 74.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 18.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 39.0,
    //             },
    //           ],
    //         },
    //         yearly: {
    //           analyticsData: [
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 64.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 27.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 21.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 44.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 19.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 24.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 61.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 47.0,
    //             },
    //             {
    //               node: "2022-10-11T11:00:00",
    //               count: 38.0,
    //             },
    //             {
    //               node: "2022-10-11T12:00:00",
    //               count: 54.0,
    //             },
    //           ],
    //         },
    //       },
    //     },
    //   ],
    // },
    {
      id: "envrSensor4",
      category: "envrSensors",
      title: "Environment Sensor#4",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor5",
      category: "envrSensors",
      title: "Environment Sensor#5",
      area: "Construction Area, Zone 4",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor6",
      category: "envrSensors",
      title: "Environment Sensor#6",
      area: "Construction Area, Zone 4",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor7",
      category: "envrSensors",
      title: "Environment Sensor#7",
      area: "Construction Area, Zone 4",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor8",
      category: "envrSensors",
      title: "Environment Sensor#8",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor9",
      category: "envrSensors",
      title: "Environment Sensor#9",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 2.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 13.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "envrSensor10",
      category: "envrSensors",
      title: "Environment Sensor#10",
      area: "Construction Area, Zone 5",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",

      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Carbon Monoxide",
          value: "25 Kg",
        },
        {
          title: "VOC's",
          value: "551",
        },
        {
          title: "Particulate",
          value: "136µg/m³",
        },
        {
          title: "Noise",
          value: "70dBA",
        },
        {
          title: "Pressure",
          value: "10hPa",
        },
        {
          title: "Light",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "carbonMonoxide",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
              ],
            },
          },
        },
        {
          type: "voc",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 13.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
              ],
            },
          },
        },
      ],
    },
  ],
  floodSensorInfoData: [
    {
      id: "floodSensor1",
      category: "floodSensor1",
      title: "Flood Sensor#1",
      area: "Construction Area, Zone 1",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "64°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },
        {
          title: "Water Level",
          value: "0.38mm",
        },
        {
          title: "Rainfall",
          value: "250mm",
        },
        {
          title: "Battery",
          value: "62%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor2",
      category: "floodSensor",
      title: "Flood Sensor#2",
      area: "Construction Area, Zone 2",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "71°C",
        },
        {
          title: "Humidity",
          value: "69%",
        },
        {
          title: "Water Level",
          value: "0.42mm",
        },
        {
          title: "Rainfall",
          value: "650mm",
        },
        {
          title: "Battery",
          value: "72%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 157.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor3",
      category: "floodSensor",
      title: "Flood Sensor#3",
      area: "Construction Area, Zone 3",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "25°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Water Level",
          value: "0.28mm",
        },
        {
          title: "Rainfall",
          value: "550mm",
        },
        {
          title: "Battery",
          value: "75%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 8.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor4",
      category: "floodSensor",
      title: "Flood Sensor#4",
      area: "Construction Area, Zone 4",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "35°C",
        },
        {
          title: "Humidity",
          value: "40%",
        },
        {
          title: "Water Level",
          value: "0.58mm",
        },
        {
          title: "Rainfall",
          value: "600mm",
        },
        {
          title: "Battery",
          value: "61%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 2.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor5",
      category: "floodSensor",
      title: "Flood Sensor#5",
      area: "Construction Area, Zone 5",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "57°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },
        {
          title: "Water Level",
          value: "0.30mm",
        },
        {
          title: "Rainfall",
          value: "700mm",
        },
        {
          title: "Battery",
          value: "72%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor6",
      category: "floodSensor",
      title: "Flood Sensor#6",
      area: "Construction Area, Zone 6",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "63°C",
        },
        {
          title: "Humidity",
          value: "87%",
        },
        {
          title: "Water Level",
          value: "0.18mm",
        },
        {
          title: "Rainfall",
          value: "600mm",
        },
        {
          title: "Battery",
          value: "60%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor7",
      category: "floodSensor",
      title: "Flood Sensor#7",
      area: "Construction Area, 4",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "71°C",
        },
        {
          title: "Humidity",
          value: "70%",
        },
        {
          title: "Water Level",
          value: "0.33mm",
        },
        {
          title: "Rainfall",
          value: "700mm",
        },
        {
          title: "Battery",
          value: "69%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor8",
      category: "floodSensor",
      title: "Flood Sensor#8",
      area: "Construction Area, Zone 8",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "48°C",
        },
        {
          title: "Humidity",
          value: "54%",
        },
        {
          title: "Water Level",
          value: "0.23mm",
        },
        {
          title: "Rainfall",
          value: "800mm",
        },
        {
          title: "Battery",
          value: "73%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 13.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor9",
      category: "floodSensor",
      title: "Flood Sensor#9",
      area: "Construction Area, Zone 9",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "87°C",
        },
        {
          title: "Humidity",
          value: "88%",
        },
        {
          title: "Water Level",
          value: "0.42mm",
        },
        {
          title: "Rainfall",
          value: "700mm",
        },
        {
          title: "Battery",
          value: "87%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 13.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor10",
      category: "floodSensor",
      title: "Flood Sensor#10",
      area: "Construction Area, Zone 10",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "62°C",
        },
        {
          title: "Humidity",
          value: "58%",
        },
        {
          title: "Water Level",
          value: "1.00mm",
        },
        {
          title: "Rainfall",
          value: "600mm",
        },
        {
          title: "Battery",
          value: "84%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor11",
      category: "floodSensor",
      title: "Flood Sensor#11",
      area: "Construction Area, Zone 11",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "69°C",
        },
        {
          title: "Humidity",
          value: "78%",
        },
        {
          title: "Water Level",
          value: "0.38mm",
        },
        {
          title: "Rainfall",
          value: "500mm",
        },
        {
          title: "Battery",
          value: "71%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor12",
      category: "floodSensor",
      title: "Flood Sensor#12",
      area: "Construction Area, Zone 12",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "76°C",
        },
        {
          title: "Humidity",
          value: "75%",
        },
        {
          title: "Water Level",
          value: "0.48mm",
        },
        {
          title: "Rainfall",
          value: "700mm",
        },
        {
          title: "Battery",
          value: "64%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor13",
      category: "floodSensor",
      title: "Flood Sensor#13",
      area: "Construction Area, Zone 13",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "63°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },
        {
          title: "Water Level",
          value: "0.28mm",
        },
        {
          title: "Rainfall",
          value: "600mm",
        },
        {
          title: "Battery",
          value: "71%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 12.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor14",
      category: "floodSensor",
      title: "Flood Sensor#14",
      area: "Construction Area, Zone 14",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "58°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },
        {
          title: "Water Level",
          value: "0.38mm",
        },
        {
          title: "Rainfall",
          value: "700mm",
        },
        {
          title: "Battery",
          value: "87%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "floodSensor15",
      category: "floodSensor",
      title: "Flood Sensor#15",
      area: "Construction Area, Zone 15",
      connectivityPercentage: "59%",
      incidentsObservation: "32 min ago",
      operationAlertObservation: "3 days ago",
      motionObservation: "5 sec ago",
      timeStamp: "12-15-2022 | 09:00 AM",
      infoIconList: [
        {
          title: "Temperature",
          value: "64°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },
        {
          title: "Water Level",
          value: "0.38mm",
        },
        {
          title: "Rainfall",
          value: "800mm",
        },
        {
          title: "Battery",
          value: "65%",
        },
      ],
      analytics: [
        {
          type: "temperature",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
              ],
            },
          },
        },
        {
          type: "humidity",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
              ],
            },
          },
        },
        {
          type: "waterLevel",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
              ],
            },
          },
        },
        {
          type: "rainFall",
          data: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 23.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 20.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 18.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export default dashboardInfoWindow;
