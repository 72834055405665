export default {
  title: "Hello Infinite English",
  profile: "Profile English",
  hello: "Hello",
  changeLang: "Change Language",
  changeTheme: "Change Theme",
  equipment: "Equipment",
  aiCameras: "AI Cameras",
  envrSensor: "Envr. Sensor",
  floodSensor: "Flood Sensor",
  search: "Search",
  noResult: "No Result Found",
  grokEye: "Grok Eye",
  raiseAlert: "Raise Alert",
  call: "Call",
  connectivity: "Connectivity or Cellular",
  incidents: "Incidents",
  operationAlert: "Operation Alert",
  motion: "Motion",
  logout: "Logout",
  dashboard: "Dashboard",
  alerts: "Alerts",
  poweredBy: "Powered by",
  copyRight: "© 2023. All Rights Reserved",
  events: "Events",
  operations: "Operations",

  temperature: "Temperature",
  humidity: "Humidity",
  carbonMonoxideWithoutUnit: "Carbon Monoxide",
  vocs: "VOC's",
  particulate: "Particulate",
  noise: "Noise",
  pressure: "Pressure",
  light: "Light",
  waterLevel: "Water Level",
  rainfall: "Rainfall",
  battery: "Battery",


  carbonMonoxide: "Carbon Monoxide (Kg)",
  voc: "VOC",
  call911: "Call 911",
  viewDetails: "View Details",
  aiCamera: "AI CAMERA",
  zone: "Zone",

  video: "Video",
  image: "Image",
  
};
