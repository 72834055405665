import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  colorSchemeHeading: (props: any) => ({
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: " 24px",
    lineHeight: "29px",
    color: "#000000",
    // margin: 30,
  }),
  googleFontHeading: (props: any) => ({
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: " 24px",
    lineHeight: "29px",
    color: "#000000",
    margin: 30,
  }),
  textAreaStyle: (props: any) => ({
    margin: "0px 0px 0px 30px",
    paddingLeft: "15px",
    width: "60%",
    height: "100px",
    border: "1px solid #93A8C1",
    color: "#445B7D",
    fontWeight: 600,
    fontSize: 17,
  }),
  textAreaStyleDisabled: (props: any) => ({
    margin: "0px 0px 0px 30px",
    paddingLeft: "15px",
    width: "60%",
    height: "100px",
    border: "1px solid #93A8C1",
    color: "#445B7D",
    fontWeight: 600,
    fontSize: 17,
    opacity: 0.3,
  }),
  inputText: (props: any) => ({
    margin: "0px 0px 0px 30px",
    width: "60%",
    height: "60px",
    paddingLeft: "15px",
    border: "1px solid #93A8C1",
    color: "#445B7D",
    fontWeight: 600,
    fontSize: 17,
  }),
  inputTextDisabled: (props: any) => ({
    margin: "0px 0px 0px 30px",
    width: "60%",
    height: "60px",
    paddingLeft: "15px",
    border: "1px solid #93A8C1",
    color: "#445B7D",
    fontWeight: 600,
    fontSize: 17,
    opacity: 0.3,
  }),
  adminRightPanelHeader: () => ({
    padding: "30px 30px 30px 30px ",
    borderBottom: "1px solid rgb(0 0 0 / 10%)",
    "& :last-child": {
      borderBottom: "none !important",
    },
  }),
  previewButton: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    color: "#1A3175 !important",
    border: "1px solid #1a3175 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
  }),
  previewButtonDisabled: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    color: "#1A3175 !important",
    border: "1px solid #1A3175 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
    opacity: "0.4 !important",
    pointerEvents: "none",
  }),
  adminHeaderButtonSection: () => ({
    display: "flex",
    justifyContent: "flex-end",
  }),
  updateButton: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    background: "#1a3175 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
    color: "white !important",
  }),

  updateButtonDisabled: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    background: "#1A3175 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
    color: "white !important",
    opacity: "0.8 !important",
    pointerEvents: "none",
  }),

  cancelButtonDisabled: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    background: "#C5C5C6 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
    color: "#040303 !important",
    opacity: "0.4 !important",
    pointerEvents: "none",
  }),
  cancelButton: () => ({
    fontFamily: "Montserrat !important",
    fontWeight: " 600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    background: "#F0F0F0 !important",
    padding: "12px 16px !important",
    marginRight: "20px !important",
    color: "#040303 !important",
  }),

  innerPanel: () => ({
    background: "#FFFFFF",
    borderRadius: "24px",
    height: "100%",
  }),
  googleFontLinkStyle: () => ({
    color: " #4464D1",
    fontWeight: 700,
    margin: "10px 30px  30px 30px",
  }),
}));
export default useStyles;
