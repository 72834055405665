import colorCodes from "./colors";

let theme = {
  defaultTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#300254",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack2,
        adminLogoBg: colorCodes.adminLogoBg,
        adminLoginButton: colorCodes.adminLoginButton,
        linkColor: colorCodes.linkColor,
      },
      grokList: {
        zoneContainerBackground: "#D9D9D9",
        zoneHeaderBackground: "#252525",
        instrumentCollapsedBackground: "#353232",
        instrumentExpandedBackground: "#4D4B4B",
        defaultFontColor: "#FFFFFF",
        highlightedFontColor: "#20E89C",
        alertFill: "#FF3F3F",
        alertStroke: "#FF7676",
        operationsFill: "#FF7676",
        operationsStroke: "#FFAF83",
        unavailableFill: "#A4A2A2",
        unavailableStroke: "#E3E4E5",
        eventsFill: "#71FF3F",
        eventsStroke: "#D2F9C4",
        defaultStroke: "#636567",
        instrumentItemSectionLabel: "#D8D8D8",
        grokBg: "#F0F0F0",
      },
      dashboard: {
        white: colorCodes.colorWhite,
        whiteGrayShade: colorCodes.whiteGrayShade,
        lightGrey2: colorCodes.lightGrey2,
        lightBlue4: colorCodes.lightBlue4,
        grayShade3: colorCodes.grayShade3,
        black: colorCodes.blackColor,
        darkBlueBlackShade: colorCodes.darkBlueBlackShade,
        darkGreenShade: colorCodes.darkGreenShade,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        blueGrayNewShade: colorCodes.blueGrayNewShade,
        darkBlueGrayShade: colorCodes.darkBlueGrayShade,
        paleGreenShade: colorCodes.paleGreenShade,
        lightBlueGrayShade: colorCodes.lightBlueGrayShade,
        darkBlueBlackShade2: colorCodes.darkBlueBlackShade2,
        lightGrey: colorCodes.lightGrey,
        skyBlueColor: colorCodes.skyBlueColor,
        darkRedColor: colorCodes.darkRedColor,
        goldenOrangeColor: colorCodes.goldenOrangeColor,
        lightGrayShade5: colorCodes.lightGrayShade5,
        grayShade: colorCodes.grayShade,
        blackGrayWhite: colorCodes.blackGrayWhite,
      },
      header: {
        headerBg: colorCodes.headerBg,
        white: colorCodes.colorWhite,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        darkGreenShade2: colorCodes.darkGreenShade2,
        darkGreenShade: colorCodes.darkGreenShade,
      },
      footer: {
        footerBg: colorCodes.footerBg,
      },
      infoDialogue: {
        TabBgColor: colorCodes.darkGreenShade2,
        whiteColor: colorCodes.colorWhite,
        blackShades: colorCodes.blackShades,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
      titleInfoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      alertsInfoContainer: {
        darkGreenShade2: colorCodes.darkGreenShade2,
        blackShades1: colorCodes.blackShades1,
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      chartElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  greenTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#68F73A",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack2,
        adminLogoBg: colorCodes.adminLogoBg,
        adminLoginButton: colorCodes.adminLoginButton,
        linkColor: colorCodes.linkColor,
      },
      grokList: {
        zoneContainerBackground: "#D9D9D9",
        zoneHeaderBackground: "#252525",
        instrumentCollapsedBackground: "#353232",
        instrumentExpandedBackground: "#4D4B4B",
        defaultFontColor: "#4D4B4B",
        highlightedFontColor: "#20E89C",
        alertFill: "#FF3F3F",
        alertStroke: "#FF7676",
        operationsFill: "#FF7676",
        operationsStroke: "#FFAF83",
        unavailableFill: "#A4A2A2",
        unavailableStroke: "#E3E4E5",
        eventsFill: "#71FF3F",
        eventsStroke: "#D2F9C4",
        defaultStroke: "#636567",
        instrumentItemSectionLabel: "#D8D8D8",
        grokBg: "#F0F0F0",
      },
      dashboard: {
        white: colorCodes.colorWhite,
        whiteGrayShade: colorCodes.whiteGrayShade,
        lightGrey2: colorCodes.lightGrey2,
        lightBlue4: colorCodes.lightBlue4,
        grayShade3: colorCodes.grayShade3,
        black: colorCodes.blackColor,
        darkBlueBlackShade: colorCodes.darkBlueBlackShade,
        darkGreenShade: colorCodes.darkGreenShade,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        blueGrayNewShade: colorCodes.blueGrayNewShade,
        darkBlueGrayShade: colorCodes.darkBlueGrayShade,
        paleGreenShade: colorCodes.paleGreenShade,
        lightBlueGrayShade: colorCodes.lightBlueGrayShade,
        darkBlueBlackShade2: colorCodes.darkBlueBlackShade2,
        lightGrey: colorCodes.lightGrey,
        skyBlueColor: colorCodes.skyBlueColor,
        darkRedColor: colorCodes.darkRedColor,
        goldenOrangeColor: colorCodes.goldenOrangeColor,
        lightGrayShade5: colorCodes.lightGrayShade5,
        grayShade: colorCodes.grayShade,
        blackGrayWhite: colorCodes.blackGrayWhite,
      },
      header: {
        headerBg: colorCodes.headerBg,
        white: colorCodes.colorWhite,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        darkGreenShade2: colorCodes.darkGreenShade2,
        darkGreenShade: colorCodes.darkGreenShade,
      },
      footer: {
        footerBg: colorCodes.footerBg,
      },
      infoDialogue: {
        TabBgColor: colorCodes.darkGreenShade2,
        whiteColor: colorCodes.colorWhite,
        blackShades: colorCodes.blackShades,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
      titleInfoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      alertsInfoContainer: {
        darkGreenShade2: colorCodes.darkGreenShade2,
        blackShades1: colorCodes.blackShades1,
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      chartElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  yellowTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#D1F557",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack2,
        adminLogoBg: colorCodes.adminLogoBg,
        adminLoginButton: colorCodes.adminLoginButton,
        linkColor: colorCodes.linkColor,
      },
      grokList: {
        zoneContainerBackground: "#D9D9D9",
        zoneHeaderBackground: "#252525",
        instrumentCollapsedBackground: "#353232",
        instrumentExpandedBackground: "#4D4B4B",
        defaultFontColor: "#4D4B4B",
        highlightedFontColor: "#20E89C",
        alertFill: "#FF3F3F",
        alertStroke: "#FF7676",
        operationsFill: "#FF7676",
        operationsStroke: "#FFAF83",
        unavailableFill: "#A4A2A2",
        unavailableStroke: "#E3E4E5",
        eventsFill: "#71FF3F",
        eventsStroke: "#D2F9C4",
        defaultStroke: "#636567",
        instrumentItemSectionLabel: "#D8D8D8",
        grokBg: "#F0F0F0",
      },
      dashboard: {
        white: colorCodes.colorWhite,
        whiteGrayShade: colorCodes.whiteGrayShade,
        lightGrey2: colorCodes.lightGrey2,
        lightBlue4: colorCodes.lightBlue4,
        grayShade3: colorCodes.grayShade3,
        black: colorCodes.blackColor,
        darkBlueBlackShade: colorCodes.darkBlueBlackShade,
        darkGreenShade: colorCodes.darkGreenShade,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        blueGrayNewShade: colorCodes.blueGrayNewShade,
        darkBlueGrayShade: colorCodes.darkBlueGrayShade,
        paleGreenShade: colorCodes.paleGreenShade,
        lightBlueGrayShade: colorCodes.lightBlueGrayShade,
        darkBlueBlackShade2: colorCodes.darkBlueBlackShade2,
        lightGrey: colorCodes.lightGrey,
        skyBlueColor: colorCodes.skyBlueColor,
        darkRedColor: colorCodes.darkRedColor,
        goldenOrangeColor: colorCodes.goldenOrangeColor,
        lightGrayShade5: colorCodes.lightGrayShade5,
        grayShade: colorCodes.grayShade,
        blackGrayWhite: colorCodes.blackGrayWhite,
      },
      header: {
        headerBg: colorCodes.headerBg,
        white: colorCodes.colorWhite,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        darkGreenShade2: colorCodes.darkGreenShade2,
        darkGreenShade: colorCodes.darkGreenShade,
      },
      footer: {
        footerBg: colorCodes.footerBg,
      },
      infoDialogue: {
        TabBgColor: colorCodes.darkGreenShade2,
        whiteColor: colorCodes.colorWhite,
        blackShades: colorCodes.blackShades,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
      titleInfoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      alertsInfoContainer: {
        darkGreenShade2: colorCodes.darkGreenShade2,
        blackShades1: colorCodes.blackShades1,
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      chartElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  redTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#C10132",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack2,
        adminLogoBg: colorCodes.adminLogoBg,
        adminLoginButton: colorCodes.adminLoginButton,
        linkColor: colorCodes.linkColor,
      },
      grokList: {
        zoneContainerBackground: "#D9D9D9",
        zoneHeaderBackground: "#252525",
        instrumentCollapsedBackground: "#353232",
        instrumentExpandedBackground: "#4D4B4B",
        defaultFontColor: "#4D4B4B",
        highlightedFontColor: "#20E89C",
        alertFill: "#FF3F3F",
        alertStroke: "#FF7676",
        operationsFill: "#FF7676",
        operationsStroke: "#FFAF83",
        unavailableFill: "#A4A2A2",
        unavailableStroke: "#E3E4E5",
        eventsFill: "#71FF3F",
        eventsStroke: "#D2F9C4",
        defaultStroke: "#636567",
        instrumentItemSectionLabel: "#D8D8D8",
        grokBg: "#F0F0F0",
      },
      dashboard: {
        white: colorCodes.colorWhite,
        whiteGrayShade: colorCodes.whiteGrayShade,
        lightGrey2: colorCodes.lightGrey2,
        lightBlue4: colorCodes.lightBlue4,
        grayShade3: colorCodes.grayShade3,
        black: colorCodes.blackColor,
        darkBlueBlackShade: colorCodes.darkBlueBlackShade,
        darkGreenShade: colorCodes.darkGreenShade,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        blueGrayNewShade: colorCodes.blueGrayNewShade,
        darkBlueGrayShade: colorCodes.darkBlueGrayShade,
        paleGreenShade: colorCodes.paleGreenShade,
        lightBlueGrayShade: colorCodes.lightBlueGrayShade,
        darkBlueBlackShade2: colorCodes.darkBlueBlackShade2,
        lightGrey: colorCodes.lightGrey,
        skyBlueColor: colorCodes.skyBlueColor,
        darkRedColor: colorCodes.darkRedColor,
        goldenOrangeColor: colorCodes.goldenOrangeColor,
        lightGrayShade5: colorCodes.lightGrayShade5,
        grayShade: colorCodes.grayShade,
        blackGrayWhite: colorCodes.blackGrayWhite,
      },
      header: {
        headerBg: colorCodes.headerBg,
        white: colorCodes.colorWhite,
        lightGrayWhiteShade: colorCodes.lightGrayWhiteShade,
        darkGreenShade2: colorCodes.darkGreenShade2,
        darkGreenShade: colorCodes.darkGreenShade,
      },
      footer: {
        footerBg: colorCodes.footerBg,
      },
      infoDialogue: {
        TabBgColor: colorCodes.darkGreenShade2,
        whiteColor: colorCodes.colorWhite,
        blackShades: colorCodes.blackShades,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
      titleInfoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubList: {
        darkBlack2: colorCodes.darkBlack2,
        darkGray: colorCodes.darkGray,
        colorWhite: colorCodes.colorWhite,
      },
      alertsInfoContainer: {
        darkGreenShade2: colorCodes.darkGreenShade2,
        blackShades1: colorCodes.blackShades1,
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      chartElement: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
        greenShades: colorCodes.greenShades,
        blueShades: colorCodes.blueShades,
        indianRed: colorCodes.indianRed,
        yellowShades: colorCodes.yellowShades,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
};

export default theme;
